function MediaItem(props) {
  return (
			<div className="card">						
			  <div className="embed-responsive embed-responsive-16by9 border">
					<iframe className="embed-responsive-item" src={props.link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			  </div>
			  <div className="card-body bg-light">
			    {/**<h5 className="card-title font-bold">{props.title}</h5>*/}
			    <p className="card-text font-regular">{props.description}</p>
			  </div>
			</div>
  );
}

export default MediaItem;
