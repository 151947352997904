import GigItem from './gig-item';
import showsTitle from '../images/shows-title-black-big.png'; 

function GigsSection() {
	
	return (
		<div>
			<a className="anchor" id="gigs-anchor"></a>
			<div id="band-members" className="container-fluid bg-light">  		
		  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
					<div className="text-center" id="band-members-title">
						<img id="crew-title" 
							className="img-fluid"
							src={showsTitle} 
							data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>		
					</div>
					
					<div className="row" name="band-members-content">
					
					
					<table className="table table-sm table-borderless font-regular text-dark">
					  <tbody>
						<GigItem
							title = 'Head Bang Party'
							date = 'THU-August 07, 2021'
							time = '8pm - Openning'
							address = 'Friedrichstadt-Palast, Friedrichstraße 107 10117  Berlin'
						/>
						
						<GigItem
							title = 'Long Live Rock&Roll evening'
							date = 'FRI-August 08, 2021'
							time = '7pm - Openning'
							address = 'Black Land, Friedrichstraße 107 10117  Berlin'
						/>
			
						<GigItem
							title = 'Head Bang Party'
							date = 'THU-August 07, 2021'
							time = '21:30pm - Openning'
							address = 'Friedrichstadt-Palast, Friedrichstraße 107 10117  Berlin'
						/>
						
						<GigItem
							title = 'Long Live Rock&Roll evening'
							date = 'FRI-August 08, 2021'
							time = '8pm - Openning'
							address = 'Black Land, Friedrichstraße 107 10117  Berlin'
						/>
					  </tbody>
					</table>
					
					
					</div>
				</div>
				<div className="col-md-1 themed-grid-col"></div>
			</div>
			</div>
		</div>
	);
}

export default GigsSection;