import './crew.css';
import CrewItem from './crew-item';
import mediaTitle from '../images/crew-title-desktop.png';
import mediaTitleMobile from  '../images/crew-title-mobile.png';
import profilePictureDogi from "../images/Dogi-profile.jpg";
import profilePictureKesha from "../images/Kesha-profile.jpg";
import profilePictureViktor from "../images/Viktor-profile3.png";

function CrewSection(crew) {

return (
	<div>
	  	<a className="anchor" id="band-members"></a>
	  	<div className="container-fluid bg-black bg-breaks">  		
		  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
	
					<div className="text-center" id="band-members-title">
						<picture>
					  		<source srcSet={mediaTitleMobile} media="(max-width: 699px)"/>
					  		<img id="crew-title"
								src={mediaTitle} 
								className="img-fluid" alt="Crew Logo"
								data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>
						</picture>
					</div>
					
					<div className="row text-white" name="band-members-content">
						<div className="card-deck">
							{/**
							<CrewItem
								picture={profilePictureMatti}
								name="Matti S.Aikio"
								shortBio="Bassbounding troll from the dark forests of Finland. Howls like a wolf and growls like a wolverine in heat."
							/>
							 */}
							<CrewItem
								picture={profilePictureViktor}
								name="Viktor Kosan"
								shortBio="Red bass-guitar, long hair, even longer fingers, young and hot straight from Berlin."
							/>

							<CrewItem
								picture={profilePictureDogi}
								name="Mr. Dogi T."
								shortBio="Like a good old whiskey, from the age of 15 Mr.T started to rock hard in bars and clubs of Berlin. Relentless heavy kick mixed with his oriental roots brings a special touch to heavy metal coctail."
							/>

							<CrewItem
								picture={profilePictureKesha}
								name="Kesha Bender"
								shortBio="Born in Siberia and raised under the burning sun of Ukraine Kesha pocess famous rebellious spirit of Cossacks which results in roaring guitar riffs and dramatic melodies."
							/>
						</div>
					</div>				
					<br/>			
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	</div>
	  	</div>
	</div>
  );
}

export default CrewSection;