import './App.css';
import logo from './images/font-v5-ukraine.png';

function Header() {
  return (
  	<div name="header">
		<nav className="navbar fixed-header navbar-expand-lg navbar-dark background bg-black">
			<a className="navbar-brand" href="#">
				<img src={logo} height="45" alt="" loading="lazy"/> 
			</a>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			  <span className="navbar-toggler-icon"></span>
			</button>

		    <div className="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul className="navbar-nav">
			      <li className="nav-item">
			        <a className="nav-link" href="#band-media">Media</a>
			      </li>
			      
				  	<li className="nav-item">
			        <a className="nav-link" href="#band-members">Crew</a>
			      </li>
{/**
					<li className="nav-item">
					    <a className="nav-link" href="#gigs-anchor">Gigs</a>
					</li>
*/}

			      <li className="nav-item">
			        <a className="nav-link" href="#footer">Contact</a>
			      </li>
 				 
			    </ul>
		  </div>

		</nav>
  	</div>
  );
}

export default Header;
