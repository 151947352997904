import './anouncement.css';
import anouncementTitle from '../images/next-show.png';

function AnouncementSection() {
	let cardStyle = {
		background: 'none',
		border: 'none'
	};
	
	return (
		<div>
			<a className="anchor" id="news-anchor"></a>
			<div className="container-fluid bg-light">	 
	
			<div id="anouncement" className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
					<div className="text-center" id="band-members-title">
						<img id="next-show-title"
							className="img-fluid"
							src={anouncementTitle} 
							data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>
					</div>
					
					<div className="text-center" id="announcement-content">

						{/**
						<h5 class="card-title font-bold text-white">Our First Gig!</h5>
					    <p class="font-regular text-white"><h5>At August 15th - 8pm, Friedrichstadt-Palast, Friedrichstraße 107 10117  Berlin we are going to play for you!</h5></p>
					   */}
						<div className="card" style={cardStyle}>
						    <div className="card-body">

						      	<h5 className="card-text font-bold text-white text-center">
									<p>November 29th</p>
									{/**<p>Record Release Party</p>*/}
									<p>Cafe Köpenick | Seelenbinderstraße 54, 12555 Berlin</p>
									{/**<p className="font-major">Bands: Hammer King, Astroking, Dawn of Age, Zmey</p>*/}
									<p>Entrenance: from 7pm, Show-time: 7.30pm</p>
								</h5>

						    </div>
						</div>
			
					</div>
				</div>
				<div className="col-md-1 themed-grid-col"></div>
			</div>
		
			</div>
		</div>
	);
}

export default AnouncementSection;