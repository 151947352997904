function CrewItem(props) {
  return (
	<div className="card">
	    <img className="card-img-top" src={props.picture}/>
	    <div className="card-body bg-dark">
	      <h5 className="card-title font-bold text-center">{props.name}</h5>
	      <p className="card-text">{props.shortBio}</p>
	    </div>
	</div>		
  );
}
/** background-color:#470202 */
export default CrewItem;
