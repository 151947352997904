import './App.css';
import Header from './header';
import HeroSection from './homepage/hero-section';
import GigsSection from './homepage/gigs-section';
import MediaSection from './homepage/media-section';
import CrewSection from './homepage/crew-section';
import AnouncementSection from './homepage/anouncement-section.js'
import Footer from './footer';

function Homepage() {
  return (
	<div>
		<Header />
		<HeroSection />
		<AnouncementSection />
		<MediaSection />
		<CrewSection />
		{/**
		<GigsSection />
		 */}
		<Footer />
	</div>
  );
}

export default Homepage;
