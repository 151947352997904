import mediaTitle from '../images/media-v4.png';
import MediaItem from './media-item';

function MediaSection() {
  return (
	<div>
	 	<a className="anchor" id="band-media"></a>
  		<div id="band-songs" className="container-fluid bg-black">  		
		  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
	
					<div>
						<div id="media-title" className="text-center">
							<img id="media-title" className="img-fluid" src={mediaTitle} alt="Media" data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>
						</div>
						
						<div className="row" id="band-songs-content">
							<div className="card-deck">
								<MediaItem 
									title = "Scars and Regrets"
									description = "When love turns to hate and joy to despair nothing is left but scars and regret."
									link = "https://www.youtube.com/embed/j6OWtAyMem8"
								/>
								
								<MediaItem 
									title = "Too much to lose"
									description = "Naja atra aslso called Chinese cobra is a highly venomous member of the true cobras"
									link = "https://www.youtube.com/embed/ixnIHbZ2w1g"
								/>
								
								<MediaItem 
									title = "Falling Down"
									description = "Would you fall down for money or rise up for freedom?"
									link = "https://www.youtube.com/embed/tJoxhHPjIPE"
								/>						
							</div>
							
						</div>
					</div>
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	</div>
  	</div>

	</div>
  );
}

export default MediaSection;
